.image-zoom-container {
    position: relative;
    overflow: hidden;
}


.zoomed-image {
    position: fixed;
    top: 20%;
    left: 35%;
    z-index: 10;
    width: 50%;
    height: 70%;
    display: none;
    background-repeat: no-repeat;
    background-size: 200%;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    pointer-events: none;
}