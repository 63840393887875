@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
  margin: 0;
  font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /* ... other styles ... */
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(244 250 241);
}

*::selection {
  color: white;
  background: #2FB95D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footerlinkheading:hover {
  color: #2FB95D !important;
}

.twitter_footer {
  margin-bottom: 0px !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background: #717171;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #717171;
}

.breathing-animation {
  animation: breathing 2s ease-in-out infinite;
}

@keyframes breathing {

  0%,
  100% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.02);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.feature-list-item {
  opacity: 0;
  animation: slideInFromLeft 0.5s ease forwards;
}

.text-glow {
  text-shadow: 0 0 5px #2FB95D, 0 0 9px #2FB95D;
}